<template>
  <div class="has-tab-bar">
    <app-bar title="" :show-back-button="true" />
    <spinner-loader v-if="loading" />
    <div class="container pt-2">
      <!-- show login or register form if not logged in -->
      <template v-if="!isAuthenticated">
        <h1 class="">Inloggen</h1>
        <p>Om iets te kunnen kopen moet je eerst even inloggen</p>
        <form @submit.prevent="login">
          <input-field
            v-model="email"
            default-value="E-mailadres"
            type="email"
            required
            class="my-4 w-80"
          />

          <input-field
            v-model="password"
            default-value="Wachtwoord"
            type="password"
            placeholder="Wachtwoord"
            required
            class="my-4 w-80"
          />

          <a
            href="#"
            class="link"
            @click.prevent="$router.push('/password-forgot')"
          >
            Wachtwoord vergeten?
          </a>

          <button
            type="submit"
            class="button button-primary mt-8 mb-8 w-80 block"
          >
            Inloggen
          </button>
        </form>
        <h2>Nog geen account?</h2>
        <p>Maak eenvoudig een gratis account aan en ga verder met afrekenen.</p>
        <button
          class="button button-primary mt-8 mb-8 w-80 block"
          @click.prevent="
            $router.push({
              path: '/register-preferences',
              query: { redirectTo: 'products/1/checkout' }
            })
          "
        >
          Gratis aanmelden
        </button>
        <h2>Gelijk afrekenen?</h2>
        <p>
          Liever geen account en gelijk afrekenen? Vul dan je wel even je
          e-mailadres in zodat we je de bevestiging en code kunnen mailen.
        </p>
        <input-field
          v-model="newEmail"
          default-value="E-mailadres"
          type="email"
          placeholder="Email"
          required
          class="my-4 w-80"
        />
        <button
          class="button button-secondary mt-8 mb-8 w-80 block"
          @click="startPayment"
        >
          Verder gaan zonder account
        </button>
      </template>
      <!-- show checkout form if logged in -->
      <template v-else>
        <h1 class="mb-2">Tennisballen</h1>
        <button
          class="button button-primary mt-8 mb-8 w-full"
          @click="startPayment"
        >
          Afrekenen
        </button>
        <p
          class="text-gray-600 text-sm italic border-l-2 border-yellow-500 pl-4 mt-4"
        >
          Let op: Na je betaling ontvang je een code per e-mail en instructies
          waar je de ballen kunt ophalen
        </p>
      </template>
      <modal
        :show="showWrongCredentialsModal"
        @close="showWrongCredentialsModal = false"
      >
        <template v-slot:header>Er gaat iets fout...</template>
        <div>
          Controleer of je inloggegevens goed zijn en probeer het opnieuw.
        </div>
      </modal>
    </div>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapGetters, mapState } from 'vuex'
import InputField from '../../components/InputField.vue'
import Modal from '../../components/Modal.vue'

export default {
  name: 'ProductCheckoutPage',
  components: { AppBar, SpinnerLoader, TabBar, InputField, Modal },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      newEmail: '',
      showWrongCredentialsModal: false
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({}),
    isAuthenticated() {
      return !!this.authId
    }
  },
  watch: {},
  created: function () {},
  methods: {
    login() {
      this.$store
        .dispatch('AUTH_REQUEST', {
          email: this.email,
          password: this.password
        })
        .then((resp) => {
          if (resp.status !== 200) {
            this.showWrongCredentialsModal = true
          }
        })
        .catch(() => {
          this.showWrongCredentialsModal = true
        })
    },
    startPayment() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.$router.push('/products/1')
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
