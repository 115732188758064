<template>
  <div class="has-tab-bar">
    <app-bar title="" :show-back-button="false" />
    <spinner-loader v-if="loading" />
    <div class="container pt-2">
      <h1 class="mb-2">Tennisballen</h1>
      <div class="flex">
        <div class="flex-1">
          <p class="text-lg mb-8">€ 9,95</p>
          <p class="mb-8">
            Een blik nieuwe tennisballen liggen voor je klaar om gelijk lekker
            te kunnen spelen
          </p>
          <div class="flex items-center mb-2">
            <icon-base class="text-green-600 w-8 mr-2" size="20">
              <icon-pin :stroke-width="2" />
            </icon-base>
            <span class="text-sm">Locatie: TC Domstad</span>
          </div>
          <div class="flex items-center mb-8">
            <icon-base class="text-green-600 w-8 mr-2" size="20">
              <icon-check :stroke-width="3" />
            </icon-base>
            <span class="text-sm">5 stuks op voorraad</span>
          </div>
          <button
            class="button button-primary mt-8 mb-8 w-full"
            @click="goToCheckout"
          >
            Ik wil deze ballen!
          </button>
        </div>
        <div class="w-48">
          <img
            class="h-40 w-40 object-contain ml-8"
            src="https://m.media-amazon.com/images/I/71iJpHMLKBL.__AC_SX300_SY300_QL70_FMwebp_.jpg"
            alt="Product image"
          />
        </div>
      </div>
    </div>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import IconCheck from '@/components/icons/IconCheck'
import IconBase from '@/components/IconBase'
import IconPin from '@/components/icons/IconPin'

export default {
  name: 'ProductDetailPage',
  components: { AppBar, IconBase, IconCheck, IconPin, SpinnerLoader, TabBar },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {
    goToCheckout() {
      this.$router.push('/products/1/checkout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
